import React from "react";
import backgroundImage from "images/banners/bg-on-premise-2x.png";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import {
  BannerTitleTilted,
  FullBackground,
  Layout,
  OnPremiseAccess,
  OnPremiseCommand,
  OnPremiseControl,
  OnPremiseInformationTechnology,
  OnPremiseOverview,
  PageContainer,
  SEO,
} from "components";
import { device } from "utils";
import { graphql } from "gatsby";

const OnPremisePage = ({ data, location }) => {
  const title = "ECL On Premise";

  return (
    <FullBackground gradient="special">
      <Layout clearNav location={location}>
        <SEO image={pageImage} title={title} uri={location.pathname} />
        <BannerTitleTilted image={backgroundImage}>{title}</BannerTitleTilted>
        <PageContainer data-cy="on-premise-page">
          <FlexContainer>
            <Content>
              <OnPremiseOverview data={data.overview} />
              <OnPremiseCommand data={data.command} />
              <OnPremiseControl data={data.control} />
              <OnPremiseAccess data={data.access} />
              <OnPremiseInformationTechnology
                data={data.informationTechnology}
              />
            </Content>
          </FlexContainer>
        </PageContainer>
      </Layout>
    </FullBackground>
  );
};

export default OnPremisePage;

export const query = graphql`
  query OnPremisePage {
    allOnPremiseJson {
      nodes {
        section
        nav
      }
    }
    overview: onPremiseJson(section: { eq: "Overview" }) {
      section
      nav
      content {
        paragraph
        text
        list {
          text
        }
      }
      table {
        col1
        col2
        col3
      }
    }
    command: onPremiseJson(section: { eq: "Command" }) {
      section
      nav
      image
      graphic
      content {
        paragraph
        text
        list {
          text
        }
      }
    }
    control: onPremiseJson(section: { eq: "Control" }) {
      section
      nav
      video {
        videoURL
        controls {
          controls
        }
      }
      content {
        text
        list {
          text
        }
      }
    }
    access: onPremiseJson(section: { eq: "Access" }) {
      section
      nav
      image
      content {
        paragraph
        text
        list {
          text
        }
      }
    }
    informationTechnology: onPremiseJson(
      section: { eq: "Information Technology" }
    ) {
      section
      nav
      content {
        paragraph
        list {
          text
        }
      }
      icons
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 8rem;

  @media ${device.lg} {
    margin-top: 4rem;
  }
`;

const Content = styled.div`
  flex: 1;
`;
